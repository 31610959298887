// src/auth/Register.js
import React, { useState } from 'react';
import pocketbase from '../pocketbase';
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast'; // Import toast

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            // Updated create data with username
            const data = {
                username: username,
                email: email,
                password: password,
                passwordConfirm: passwordConfirm, // Make sure your PocketBase collection has this field
                // Add any other user fields you require
            };
    
            // Create a new user record in the 'users' collection
            await pocketbase.collection('users').create(data);
    
            // (optional) send an email verification request
            await pocketbase.collection('users').requestVerification(email);
    
            // Show success toast
            toast.success('Registration successful! Please log in.');
    
            // If the registration is successful, navigate to the login page
            navigate('/auth/login');
        } catch (error) {
            console.error('Registration failed:', error);
            // Show error toast
            toast.error('User already exists or registration failed. Please try again.');
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-squiz-darkest">
            <div className="w-full max-w-xs">
                <form onSubmit={handleRegister} className="bg-squiz-dark-blue shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                    <h1 className="block text-white text-xl font-bold mb-4">Register</h1>
                    <div className="mb-4">
                        <input
                            className="input input-bordered w-full text-squiz-darkest"
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="input input-bordered w-full text-squiz-darkest"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="input input-bordered w-full text-squiz-darkest"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="input input-bordered w-full text-squiz-darkest"
                            type="password"
                            placeholder="Confirm Password"
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-primary mb-4 text-squiz-white" type="submit">
                        Register
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Register;