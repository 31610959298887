// src/auth/Login.js
import React, { useState, useEffect } from 'react';
import pocketbase from '../pocketbase';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // Check if the user is already logged in
    useEffect(() => {
        if (pocketbase.authStore.model) {
            navigate('/auth/dashboard'); // Adjust the path to your dashboard route if necessary
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const authData = await pocketbase.collection('users').authWithPassword(
                email,
                password
            );

            if (authData) {
                toast.success('Logged in successfully!');
                navigate('/auth/dashboard'); // Adjust the path to your dashboard route if necessary
            }
        } catch (error) {
            console.error('Login failed:', error);
            toast.error('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-squiz-darkest">
            <div className="w-full max-w-xs">
                <form onSubmit={handleLogin} className="bg-squiz-dark-blue shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                    <h1 className="block text-white text-xl font-bold mb-4">Login</h1>
                    <div className="mb-4">
                        <input
                            className="input input-bordered w-full text-squiz-darkest"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="input input-bordered w-full text-squiz-darkest"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-primary text-squiz-white mb-4" type="submit">
                        Login
                    </button>
                    <Link to="/auth/register" className="btn btn-accent text-squiz-white btn-block">
                        Register
                    </Link>
                </form>
            </div>
        </div>
    );
};

export default Login;