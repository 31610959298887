// src/components/Container.js
import React from 'react';

const Container = ({ children }) => {
  return (
    <div className="mx-auto px-4 max-w-6xl" style={{ maxWidth: '1440px' }}>
      {children}
    </div>
  );
};

export default Container;