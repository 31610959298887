// src/middleware/RequireAuth.js
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import pocketbase from '../pocketbase';

const RequireAuth = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toastShown = useRef(false); // Ref to keep track if the toast has been shown

    useEffect(() => {
        const user = pocketbase.authStore.model;
        if (!user && !toastShown.current) {
            // Only show toast if it hasn't been shown before
            toast.error('You need to be signed in to see this');
            toastShown.current = true; // Mark toast as shown
            navigate('/auth/login', { state: { from: location }, replace: true });
        }

        // When the component is unmounted or the location changes, reset the ref
        return () => {
            toastShown.current = false;
        };
    }, [navigate, location]);

    return <Outlet />;
};

export default RequireAuth;