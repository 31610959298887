// src/components/ImageTable.js
import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const ImageTable = ({ files, uploadProgress, handleUploadAndProcess, isUploading, handleFormatChange, setFiles }) => {
    const [globalFormat, setGlobalFormat] = useState('webp'); // Default format
    const readyForDownloadCount = files.filter(file => file.downloadUrl).length;
    // Helper function to format bytes as megabytes
    const formatBytesAsMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2) + ' MB';

    const handleGlobalFormatChange = (e) => {
        const newFormat = e.target.value;
        setGlobalFormat(newFormat);
    
        // Call handleFormatChange for each file
        files.forEach((file) => {
            handleFormatChange(file.name, newFormat);
        });
    };

    const handleFormatChangeForAll = (newFormat) => {
        setFiles((prevFiles) => prevFiles.map((file) => ({
            ...file,
            format: newFormat
        })));
    };

    // Helper function to truncate file name to 10 characters plus extension
    const truncateFileName = (name) => {
        const maxLength = 10;
        const extension = name.substring(name.lastIndexOf('.'));
        const truncatedName = name.length > maxLength ? name.substring(0, maxLength) + '...' + extension : name;
        return truncatedName;
    };

    const downloadAllImages = async () => {
        const zip = new JSZip();
        const downloadPromises = files.map((file) => {
            // Use the format property to determine the file extension
            const fileExtension = file.format || 'webp';
            const fileName = file.name.endsWith(`.${fileExtension}`) ? file.name : file.name.replace(/\.[^.]+$/, `.${fileExtension}`);
            const fileUrl = file.downloadUrl;

            return fetch(fileUrl)
                .then((response) => {
                    if (response.status === 200) return response.blob();
                    throw new Error('Network response was not ok.');
                })
                .then((blob) => {
                    zip.file(fileName, blob); // Add the file to the ZIP with the correct name and format
                })
                .catch((error) => console.error('Error downloading file:', error));
        });

        await Promise.all(downloadPromises);

        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'downloaded_images.zip');
        });
    };

    return (
        <div>
            {/* Top button group */}
            <div className="flex justify-between mb-4">
                <div>
                    {/* Clear button on the left */}
                    <button className="btn btn-secondary border-dashed border-2 btn-outline mr-2" onClick={() => window.location.reload()}>
                        Clear
                    </button>
                    {/* Go button next to Clear button */}
                    <button
                        onClick={handleUploadAndProcess}
                        className="btn btn-success"
                        disabled={isUploading || files.length === 0 || files.every(file => uploadProgress[file.name].status !== 'waiting')}
                    >
                        Go!
                    </button>
                </div>

                <div>
                    <select value={globalFormat} onChange={handleGlobalFormatChange} className="mr-2 select select-primary max-w-xs text-squiz-primary bg-squiz-accent">
                        <option value="webp">WebP</option>
                        <option value="jpg">JPG</option>
                        <option value="png">PNG</option>
                        {/* ... add other formats as needed ... */}
                    </select>
                    {readyForDownloadCount > 2 && (
                        <button className="btn btn-success" onClick={downloadAllImages}>
                            Download All
                        </button>
                    )}
                </div>

            </div>

            <div className="overflow-auto max-h-[400px]">
                <table className="table w-full text-squiz-white">
                    <thead>
                        <tr className="text-squiz-white">
                            <th>File Name</th>
                            <th>Status</th>
                            <th>Progress</th>
                            <th>Saved</th>
                            <th>Format</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file) => {
                            const progressInfo = uploadProgress[file.name];
                            const extension = file.name.substring(file.name.lastIndexOf('.'));
                            const truncatedName = file.name.length > 10 ? file.name.substring(0, 10) + '...' + extension : file.name;
                            return (
                                <tr key={file.name}>
                                    <td title={file.name}>{truncatedName}</td>
                                    <td>{progressInfo ? progressInfo.status : 'Waiting'}</td>
                                    <td>
                                        {progressInfo
                                            ? `${progressInfo.progress}%`
                                            : '0%'}
                                        <progress
                                            className="progress progress-success w-full"
                                            value={progressInfo ? progressInfo.progress : 0}
                                            max="100"></progress>
                                    </td>
                                    <td>{file.saved_mb ? file.saved_mb + ' MB' : 'N/A'}</td>
                                    <td>
                                        {/* Format select dropdown */}
                                        <select
                                            value={file.format}
                                            onChange={(e) => handleFormatChange(file.name, e.target.value)}
                                            className="select select-primary w-full max-w-xs text-squiz-primary bg-squiz-accent"
                                            disabled={isUploading}
                                        >
                                            <option value="webp">WEBP</option>
                                            <option value="jpg">JPG</option>
                                            <option value="png">PNG</option>
                                        </select>
                                    </td>
                                    <td>
                                        {progressInfo && progressInfo.status === 'done' && (
                                            <a href={file.downloadUrl} download={file.name} className="hover:text-[#fff]">
                                                Download
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ImageTable;