// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import pocketbase from '../pocketbase';


const Navbar = () => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userAvatar, setUserAvatar] = useState('');

    useEffect(() => {
        const user = pocketbase.authStore.model;
        // Set the user avatar URL or use a default if it doesn't exist
        setUserAvatar(user?.avatarUrl || '/default.png');
    }, []);

    const handleLogout = () => {
        // Clear the authenticated user's data from the auth store
        pocketbase.authStore.clear();
        // Redirect to the login page
        navigate('/auth/login');
    };

    return (
        // Container with max-width and centered
        <div className="flex justify-center py-4 px-4">
            <nav className="w-full max-w-screen-2xl bg-squiz-darkest py-2 px-4 flex items-center justify-between border-b border-squiz-lighter">
                <div className="flex items-center">
                    {/* Logo and brand name in a flex container */}
                    <a href="/auth/dashboard" className="text-xl text-white font-bold px-2 flex items-center">
                        <img src="/logo2.png" alt="Logo" className="w-12 mr-2" />
                        SquizRex
                    </a>
                </div>
                <div className="flex items-center">
                    {/* Dashboard Link */}
                    <Link to="/auth/dashboard" className="text-white px-3 py-2 rounded-md text-sm font-medium">
                        Dashboard
                    </Link>

                    {/* Dropdown Menu Trigger */}
                    <div className="relative">
                        <button
                            onClick={() => setIsDropdownOpen((prev) => !prev)}
                            className="text-white py-2 rounded-md text-sm font-medium focus:outline-none"
                        >
                            <img src={userAvatar} alt="User avatar" className="h-8 w-8 rounded-full" />
                        </button>
                        {isDropdownOpen && (
                            <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-squiz-dark-blue py-1 z-10">
                                {/* <Link
                                    to="/profile"
                                    className="block px-4 py-2 text-sm text-white hover:bg-squiz-lighter"
                                >
                                    Profile
                                </Link> */}
                                <Link
                                    to="/auth/settings"
                                    className="block px-4 py-2 text-sm text-white hover:bg-squiz-lighter"
                                >
                                    Settings
                                </Link>
                                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleLogout();
                    }}
                    className="block px-4 py-2 text-sm text-white hover:bg-squiz-lighter"
                >
                    Logout
                </a>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;