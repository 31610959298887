// src/auth/Settings.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import pocketbase from '../pocketbase';
import Navbar from '../components/Navbar';
import Container from '../components/Container';
import { toast } from 'react-hot-toast';

const Settings = () => {
    const [user, setUser] = useState(pocketbase.authStore.model);
    const [email, setEmail] = useState(user ? user.email : '');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [avatarFile, setAvatarFile] = useState(null);

    useEffect(() => {
        if (!user) {
            navigate('/auth/login');
        }
    }, [user, navigate]);

    const handleAvatarSubmit = async (e) => {
        e.preventDefault();
    
        // Create a FormData object to send the avatar file
        const formData = new FormData();
        if (avatarFile) {
            formData.append('avatar', avatarFile);
        } else {
            toast.error('Please select an avatar file to upload.');
            return;
        }
    
        try {
            const userId = user.id;
            // Perform the upload
            const response = await pocketbase.collection('users').update(userId, formData);
    
            // Assuming 'avatar' is the name of the file field in your users collection
            const imageUrl = pocketbase.files.getUrl(response, response.avatar);
    
            // Perform an additional update to set the avatarUrl field if necessary
            // Note: This step may not be required if PocketBase automatically updates the avatarUrl field when the avatar file is uploaded.
            await pocketbase.collection('users').update(userId, { avatarUrl: imageUrl });
    
            toast.success('Avatar updated successfully!');
            setAvatarFile(null);
            navigate('/auth/dashboard');
        } catch (error) {
            toast.error('Failed to update avatar: ' + error.message);
        }
    };

    return (
        <div>
            <Navbar />
            <Container>
                <h1 className="text-white text-xl font-bold mb-4">Avatar</h1>
                <form onSubmit={handleAvatarSubmit} className="bg-squiz-dark-blue shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col" encType="multipart/form-data">
                    <h2 className="text-white text-lg font-bold mb-4">Change Avatar</h2>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2">
                            Avatar:
                            <input
                                type="file"
                                onChange={(e) => setAvatarFile(e.target.files[0])}
                                accept="image/*" // Accept only image files
                            />
                        </label>
                    </div>
                    <button className="btn btn-primary text-squiz-white mb-4" type="submit">
                        Upload Avatar
                    </button>
                </form>
            </Container>
        </div>
    );
};

export default Settings;