// src/components/ImageUploader.js
import React, { useState, useEffect } from 'react';
import Resizer from 'react-image-file-resizer';
import UTIF from 'utif';
import pocketbase from '../pocketbase';
import ImageTable from './ImageTable';
import { FileInput, Label } from "flowbite-react";


const ImageUploader = () => {
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false); // State to track uploading status
    const [uploadProgress, setUploadProgress] = useState({});

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Perform state cleanup here
            setFiles([]);
            setUploadProgress({});
            // etc.
    
            // Optionally, you can show a confirmation dialog
            // event.returnValue = 'Are you sure you want to leave?';
        };
    
        // Add the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Remove the event listener on cleanup
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleFormatChange = (fileName, newFormat) => {
        // Update the files state
        setFiles((prevFiles) => prevFiles.map((file) => {
            if (file.name === fileName) {
                return {
                    ...file,
                    format: newFormat,
                    status: 'Waiting', // Reset status
                    downloadUrl: '', // Clear download URL
                    image: '', // Clear image preview
                    progress: 0 // Reset progress
                };
            }
            return file;
        }));
        
        // Update the uploadProgress state
        setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [fileName]: { ...prevProgress[fileName], status: 'waiting', progress: 0 },
        }));
    };

    const convertImage = (file, format) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onerror = () => {
                console.error('FileReader encountered an error.');
                reject(new Error('FileReader error.'));
            };
    
            // Handle TIFF images
            if (file.type === 'image/tiff' || file.type === 'image/tif') {
                reader.onload = (event) => {
                    try {
                        const ifds = UTIF.decode(event.target.result);
                        if (ifds.length === 0) {
                            throw new Error('No images found in TIFF file');
                        }
                        UTIF.decodeImage(event.target.result, ifds[0]);
                        const rgba = UTIF.toRGBA8(ifds[0]);
    
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = ifds[0].width;
                        canvas.height = ifds[0].height;
                        const imgData = new ImageData(new Uint8ClampedArray(rgba), canvas.width, canvas.height);
                        ctx.putImageData(imgData, 0, 0);
    
                        canvas.toBlob((blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                console.error('Canvas toBlob failed.');
                                reject(new Error('Canvas toBlob failed'));
                            }
                        }, 'image/webp', 0.8);
                    } catch (error) {
                        console.error('Error converting TIFF image:', error);
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                // Handle non-TIFF images (JPEG, PNG, WebP)
                reader.onload = (event) => {
                    try {
                        const img = new Image();
                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            canvas.width = img.width;
                            canvas.height = img.height;
                            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
                            let mimeType = 'image/jpeg'; // Default MIME type
                            let quality = 0.8; // Default quality for lossy formats
    
                            switch (format) {
                                case 'webp':
                                    mimeType = 'image/webp';
                                    break;
                                case 'png':
                                    mimeType = 'image/png';
                                    quality = 1.0; // PNG is lossless
                                    break;
                                case 'jpg':
                                default:
                                    mimeType = 'image/jpeg';
                                    quality = 0.7; // Lower quality for smaller file size
                                    break;
                            }
    
                            canvas.toBlob((blob) => {
                                if (blob) {
                                    resolve(blob);
                                } else {
                                    console.error('Canvas toBlob failed.');
                                    reject(new Error('Canvas toBlob failed'));
                                }
                            }, mimeType, quality);
                        };
    
                        img.onerror = () => {
                            console.error('Image object encountered an error.');
                            reject(new Error('Image onload error'));
                        };
    
                        img.src = event.target.result;
                    } catch (error) {
                        console.error('Error during image conversion:', error);
                        reject(error);
                    }
                };
    
                reader.readAsDataURL(file);
            }
        });
    };

    const convertToWebP = (file) => new Promise((resolve, reject) => {
        //console.log('Converting to WebP:', file);
        if (!(file instanceof Blob)) {
            console.error('The provided value is not a Blob or File.');
            reject(new TypeError("The provided value is not a Blob or File"));
            return;
        }

        const reader = new FileReader();

        reader.onerror = () => {
            console.error('FileReader encountered an error.');
            reject(new Error('FileReader error.'));
        };

        // Handle TIFF images
        if (file.type === 'image/tiff' || file.type === 'image/tif') {
            reader.onload = (event) => {
                try {
                    //console.log('TIFF image loaded, beginning conversion.');
                    // Parse the TIFF image from the ArrayBuffer
                    const ifds = UTIF.decode(event.target.result);
                    if (ifds.length === 0) throw new Error('No images found in TIFF file');

                    // Decode the first image in the TIFF file
                    UTIF.decodeImage(event.target.result, ifds[0]);
                    const rgba = UTIF.toRGBA8(ifds[0]); // Uint8Array with RGBA pixels

                    // Create a canvas and draw the image onto it
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = ifds[0].width;
                    canvas.height = ifds[0].height;
                    const imgData = new ImageData(new Uint8ClampedArray(rgba), canvas.width, canvas.height);
                    ctx.putImageData(imgData, 0, 0);

                    // Convert the canvas to a Blob as WebP
                    canvas.toBlob((blob) => {
                        if (blob) {
                            //console.log('Canvas toBlob successful, resolving promise with WebP Blob.');
                            // Resolve the promise with the blob in WebP format
                            resolve(blob);
                        } else {
                            console.error('Canvas toBlob failed.');
                            reject(new Error('Canvas toBlob failed'));
                        }
                    }, 'image/webp', 0.8); // Adjust quality from 0 to 1 as needed
                } catch (error) {
                    console.error('Error converting TIFF image:', error);
                    reject(error);
                }
            };
            //console.log('Reading TIFF file as array buffer.');
            reader.readAsArrayBuffer(file);
        } else {
            // Handle non-TIFF images
            reader.onload = (event) => {
                //console.log('Non-TIFF image loaded, creating Image object for conversion.');
                const img = new Image();
                img.onload = () => {
                    //console.log('Image object onload triggered, drawing on canvas.');
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((blob) => {
                        if (blob) {
                            //console.log('Canvas toBlob successful, resolving promise with WebP Blob.');
                            // Resolve the promise with the blob in WebP format
                            resolve(blob);
                        } else {
                            console.error('Canvas toBlob failed.');
                            reject(new Error('Canvas toBlob failed'));
                        }
                    }, 'image/webp', 0.8); // Adjust quality from 0 to 1 as needed
                };
                img.onerror = () => {
                    console.error('Image object encountered an error.');
                    reject(new Error('Image onload error'));
                };
                //console.log('Setting Image object src attribute to begin loading.');
                img.src = event.target.result;
            };
            //console.log('Reading non-TIFF file as Data URL.');
            reader.readAsDataURL(file); // Read as Data URL for non-TIFF
        }
    });

    const handleUploadAndProcess = async () => {
        setIsUploading(true);
        const user = pocketbase.authStore.model;
        const collectionName = 'images';
        const statsCollectionName = 'stats';
    
        // Filter only files with 'waiting' status
        const waitingFiles = files.filter(file => file.status === 'Waiting');
    
        for (const fileObj of waitingFiles) {
            // Set initial progress to indicate the start of processing
            setUploadProgress(prevProgress => ({
                ...prevProgress,
                [fileObj.name]: { ...prevProgress[fileObj.name], status: 'starting', progress: 10 },
            }));
    
            try {
                // Convert the image to the selected format
                const imageBlob = await convertImage(fileObj.file, fileObj.format);
                //console.log('Converted Blob:', imageBlob);
                // Update progress after conversion
                setUploadProgress(prevProgress => ({
                    ...prevProgress,
                    [fileObj.name]: { ...prevProgress[fileObj.name], status: 'compressing', progress: 40 },
                }));
    
                // Calculate the savings
                const originalSize = fileObj.size;
                const compressedSize = imageBlob.size;
                const savedBytes = originalSize - compressedSize;
                const savedPercent = ((savedBytes / originalSize) * 100).toFixed(2);
                const savedMb = (savedBytes / (1024 * 1024)).toFixed(2);
    
                // Prepare formData to be sent
                const formData = new FormData();
                const newFileName = fileObj.name.replace(/\.[^.]+$/, `.${fileObj.format}`);
                formData.append('image', imageBlob, newFileName);
                formData.append('belongs_to', user.id);
                formData.append('saved_percent', savedPercent);
                formData.append('saved_mb', savedMb);
    
                // Set progress to indicate upload is starting
                setUploadProgress(prevProgress => ({
                    ...prevProgress,
                    [fileObj.name]: { ...prevProgress[fileObj.name], status: 'uploading', progress: 70 },
                }));
    
                // Attempt to upload the image to the PocketBase collection
                const record = await pocketbase.collection(collectionName).create(formData);
                //('Upload Record:', record);
    
                // After successful upload, create a new row in the 'stats' collection
                await pocketbase.collection(statsCollectionName).create({
                    mb_saved: savedMb
                });
    
                // Construct the image base URL
                const baseUrl = `https://pb.squizrex.com/api/files/${record.collectionId}/${record.id}/`;
                const thumbnailUrl = `${baseUrl}${record.image}?thumb=100x100`; // Thumbnail URL
                const downloadUrl = `${baseUrl}${record.image}?download=1`; // Download URL
    
                // Update progress to indicate upload is complete
                setUploadProgress(prevProgress => ({
                    ...prevProgress,
                    [fileObj.name]: { progress: 100, status: 'done' },
                }));
    
                // Update the files state with the new file information
                setFiles(prevFiles => prevFiles.map(f => f.name === fileObj.name ? {
                    ...f,
                    size: compressedSize,
                    saved_percent: savedPercent,
                    saved_mb: savedMb,
                    status: 'Done',
                    image: thumbnailUrl, // Assign thumbnail URL to the 'image' property
                    downloadUrl: downloadUrl // Download URL
                } : f));
            } catch (error) {
                // Handle any errors during the upload or conversion
                console.error('Upload or conversion failed:', error);
                setUploadProgress(prevProgress => ({
                    ...prevProgress,
                    [fileObj.name]: { ...prevProgress[fileObj.name], status: 'failed', progress: 0 },
                }));
            }
        }
    
        setIsUploading(false);
    };
    
    const handleImageSelection = (e) => {
        const fileList = e.dataTransfer ? Array.from(e.dataTransfer.files) : Array.from(e.target.files);
    
        // Initialize progress, status, and format for each file to 'waiting' and default format 'webp'
        const newUploadProgress = { ...uploadProgress };
        const newFiles = fileList.map(file => {
            newUploadProgress[file.name] = { progress: 0, status: 'waiting' };
            return {
                file: file, // Store the actual file object
                name: file.name,
                size: file.size,
                status: 'Waiting',
                format: 'webp', // Default format
                image: '', // Placeholder if you want to show a preview or keep it empty
                downloadUrl: '' // Placeholder for the download URL
            };
        });
    
        setUploadProgress(newUploadProgress);
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    };

    return (
        <div className="relative">
            <div className="flex w-full items-center justify-center mt-4 mb-4"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.preventDefault();
                    handleImageSelection(e);
                }}>
                <Label htmlFor="dropzone-file" className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-squiz-white bg-squiz-accent hover:bg-squiz-accent-darker">
                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-upload my-4 text-squiz-white">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                            <path d="M7 9l5 -5l5 5"></path>
                            <path d="M12 4v12"></path>
                        </svg>
                        <p className="mb-2 text-sm text-squiz-white">
                            <span className="font-semibold">Click to upload</span> or drag and drop
                        </p>
                        <p className="text-xs text-squiz-white">SVG, PNG, JPG or GIF (Max. 200mb)</p>
                    </div>
                    <FileInput
                        id="dropzone-file"
                        type="file"
                        multiple
                        onChange={handleImageSelection}
                        className="hidden"
                    />
                </Label>
            </div>
            <div className="mt-4 flex justify-center">

            </div>
            <ImageTable
                files={files}
                uploadProgress={uploadProgress}
                handleUploadAndProcess={handleUploadAndProcess}
                isUploading={isUploading}
                handleFormatChange={handleFormatChange}
                setFiles={setFiles}
            />
        </div>
    );
};

export default ImageUploader;