// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RequireAuth from './middleware/RequireAuth';
import { Toaster } from 'react-hot-toast';
import Frontpage from './public/Frontpage';
import Login from './auth/Login';
import Register from './auth/Register';
import Dashboard from './auth/Dashboard';
import Settings from './auth/Settings';

const App = () => {
  return (
    <>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
      <Router>
        <Routes>
          <Route path="/" element={<Frontpage />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route element={<RequireAuth />}>
            <Route path="/auth/dashboard" element={<Dashboard />} />
            <Route path="/auth/settings" element={<Settings />} />
            {/* ... other protected routes */}
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;