// src/auth/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure to import useNavigate
import pocketbase from '../pocketbase';
import Navbar from '../components/Navbar';
import Container from '../components/Container';
import ImageUploader from '../components/ImageUploader';

const Dashboard = () => {
    const [user, setUser] = useState(pocketbase.authStore.model);
    const navigate = useNavigate();

    // Use the approved field directly from the user object
    const isUserApproved = user && user.approved;

    return (
        <div>
            <Navbar />
            <Container>
                {isUserApproved ? (
                    <ImageUploader />
                ) : (
                    <p>Your account is not approved for uploading images.</p>
                )}
            </Container>
        </div>
    );
};

export default Dashboard;