import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase'; // Make sure this is the correct import
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';

const pb = new PocketBase('https://pb.squizrex.com'); // Replace with your PocketBase instance URL

const Frontpage = () => {
    const navigate = useNavigate();
    const [totalMbSaved, setTotalMbSaved] = useState('...');
    const [imageCount, setImageCount] = useState('...');

    // Function to fetch and sum the total MB saved from the 'stats' collection
    const updateStats = async () => {
        try {
            const records = await pb.collection('stats').getFullList();
            const totalMbSaved = records.reduce((total, record) => total + parseFloat(record.mb_saved || 0), 0);
            const totalCount = records.length; // Get the total count of images
            setTotalMbSaved(totalMbSaved.toFixed(2)); // Update state with the new total
            setImageCount(totalCount); // Update state with the image count
        } catch (error) {
            console.error('Error fetching stats:', error);
            setTotalMbSaved('Error');
            setImageCount('Error');
        }
    };

    useEffect(() => {
        // Initial fetch of the stats
        updateStats();

        // Subscribe to changes in any stats record
        const unsubscribe = pb.collection('stats').subscribe('*', (e) => {
            if (['create', 'update', 'delete'].includes(e.action)) {
                // Update the stats when a change is detected
                updateStats();
            }
        });

        // Clean up the subscription when the component is unmounted
        return () => {
            pb.collection('stats').unsubscribe('*');
        };

    }, []);

    const goToLogin = () => {
        navigate('/auth/login');
    };

    const currentDate = new Date().toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <div className="max-w-md mx-auto">
                    <h1 className="text-5xl text-squiz-white font-bold">Welcome to Squizrex</h1>
                    <p className="py-6 text-squiz-white">Compressing and converting your images should be easy and accessible, not cost a fortune. Get ready for a tool that simplifies your workflow without breaking the bank.</p>
                </div>
                {/* Wrap stats in a flex container */}
                <div className="flex justify-center space-x-4">
                    <div className="stat">
                        <div className="stat-title text-squiz-white">Total MB saved</div>
                        <div className="stat-value text-squiz-white">
                            {totalMbSaved !== '...' && totalMbSaved !== 'Error' ? (
                                <Odometer value={parseFloat(totalMbSaved)} format="(.ddd),dd" />
                            ) : (
                                <span>{totalMbSaved}</span>
                            )}
                        </div>
                    </div>
                    <div className="stat">
                        <div className="stat-title text-squiz-white">Images Compressed</div>
                        <div className="stat-value text-squiz-white">
                            {imageCount !== '...' && imageCount !== 'Error' ? (
                                <Odometer value={imageCount} format="d" />
                            ) : (
                                <span>{imageCount}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Frontpage;